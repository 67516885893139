<template>
    <PageHeader :title="title" />

    <!-- Cписок моніторингів -->
    <tablecustom 
        :key="componentKey"
        :tabs="othertabs"
        :pages="this.objPages"
        :dataParams="this.dataParams"
        :objParams="this.objParams"
        :rows="this.rows"
        :columns="this.columns"
        :createdform="true"
        @create="this.showModalCreate = true"
        @search="searchB"
        @changeFilter="changeFilter"
        @changeTab="changeFilter"
        @addCategory=""
        @getdata="this.getdata()"
        @open="open"
        @sortColumn="sortColumn"
    />

    <!-- Передивитися картку моніторингу -->
    <viewCard 
        v-if="this.showModalView == true"
        :obj="this.objCard"
        @close="this.showModalView = false"
        @getdata="this.getdata()"
        @edit="edit"
        @close_getData="this.showModalView = false; this.getdata()"
        @addApplic="addApplic"
    />

    <!-- Створити заявку на моніторинг -->
    <createForm 
        v-if="this.showModalCreate == true"
        :obj="this.objCard"
        :perm="this.perm"
        @close="this.showModalCreate = false"
        @getdata="this.getdata()"
    />

    <!-- Cтворити заявку на дзвінок -->
    <createApplic 
        v-if="this.showModalCreateApplic === true"
        :obj="this.objCard"
        @close="this.showModalCreateApplic = false"
    />
</template>

<script>
import createApplic from "@/views/application/createapplic.vue"
import tablecustom from '@/components/globaltable/index.vue' 
import PageHeader from "@/components/page-header";
import viewCard from './view/index.vue'
import createForm from './create.vue'
import {Monitoring} from '@/API'
import { mutatePriorityTicket, mutateMonitoringType } from '@/usabilityScripts/globalMutate'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Monitoring()

export default {
    data() {
        return {
            showFilterBox: true,
            othertabs: [
                {
                    title: this.$t('Payment'),
                    tab: "type",
                    color: "#3577f1",
                    obj: "type",
                    value: "payments",
                    checked: true
                },
                {
                    title: this.$t('trouble_client'),
                    tab: "type",
                    color: "#f7b84b",
                    obj: "type",
                    value: "problems",
                    checked: true
                },
                {
                    title: this.$t('desagree_from_program'),
                    tab: "type",
                    color: "#e21515",
                    obj: "type",
                    value: "desagree",
                    checked: true
                },
                {
                    title: this.$t('other'),
                    tab: "type",
                    color: "#540de0",
                    obj: "type",
                    value: "another",
                    checked: true
                },
            ],
            perm: false,
            showModalView: false,
            showModalCreate: false,
            objCard: {},
            title: this.$t('monitoringLabel'),
            rows: [],
            objPages: {},
            objParams: {
                priority: '', 
                type: '',
                hideMonitoringByWorker: false,
                of: "priority",
            },
            dataParams: {
                status: true,
                page: "monitoring"
            },
            columns: [
                {
                    name: "ID",
                    text: "monitoringId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("acc"),
                    text: "number",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Cause"),
                    text: "reason",
                    align: "left",
                    status: true,
                    needFixed: true
                },
                {
                    name: this.$t("date_time"),
                    text: "createDatetime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Manager"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "left",
                    status: true,
                    sort: "",
                    mutate: (item) => this.mutatePriority(item).name,
                    mutateClass: (item) => this.mutatePriority(item).color
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateType(item).name,
                    mutateClass: (item) => this.mutateType(item).color
                },
                {
                    name: this.$t("appointed"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.mutateAppointed(item, obj),
                },
                {
                    name: this.$t("lastCheck"),
                    text: "checkDateTime",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateCheckDateTime(item).name,
                    mutateClass: (item) => this.mutateCheckDateTime(item).color
                },
            ],
            componentKey: 0,
            showModalCreateApplic: false
        }
    },
    components: {
        PageHeader,
        tablecustom,
        viewCard,
        createForm,
        createApplic
    },
    methods: {
        addApplic(form) {
            this.objCard = {
                userPhone: form.number, 
                comment: this.$t("fromMonitoring") + ":", //коммент
                userName: "", //имя клиента
                userEmail: "", //почта клиента
                priority: 0, //приоритет заявки
                status: "new", //статус заявки
                callDatetime: "", //время звонка
                action: '',
            }

            this.showModalCreateApplic = true
        },
        mutateCheckDateTime(item) {  
            var color, name

            if(item === null) {
                name = "-"
            } else if(new Date().getTime() - (7 * 24 * 3600 * 1000) > new Date(item).getTime()) {
                color = "badge bg-danger"
                name = item
            } else {
                name = item
            }

            return {color: color, name: name}
        },
        sortColumn(e, ot) {
            if(!this.objParams["ot"]) {
                this.objParams["ot"] = 'asc'
            } else if(this.objParams.ot === 'asc') {
                this.objParams["ot"] = 'desc'
            } else if(this.objParams["ot"] === 'desc') {
                delete this.objParams.ot
            }
            this.getdata()
        },
        edit(obj) {
            this.objCard = obj
            this.perm = true
            this.showModalCreate = true
        },
        mutateAppointed(item, obj) {
            console.log(...arguments);
            return obj.assignedWorkers[0] ? obj.assignedWorkers[0].workerName : "-"
        },
        changeFilter(name, value) {
            console.log(name, value)
            if(name === 'all') {
                this.objParams.type = ""
                this.objParams.hideMonitoringByWorker = false
                this.objParams.priority = ""
                this.objParams.of = "priority"
            } else {
                this.objParams[name] = value
            }
            this.getdata()
        },
        open: function(e){
            // відкриття моніторингу
            console.log(e)
            if(e.monitoringId) {
                apiServe.getMonitoring(e.monitoringId).then(result => {
                    if(result.status == 'done') {
                        this.objCard = result.data
                        this.showModalView = true
                    } else {
                        this.$toast.error(this.$t('error'))


                        var errorText = 'Не вийшло отримати моніторинг'; 
                        var statusRequest = result.status;
                        var api = 'getMonitoring';
                        var fileRequest = 'src/views/monitoring/index.vue';
                        var params = e.monitoringId;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            } else {

            }
        },
        prevOpen(e){
        },
        nextOpen(e){
        },
        mutatePriority(e){
            return mutatePriorityTicket(e)
        },
        mutateType(e) {
            return mutateMonitoringType(e)
        },
        getdata() {
            apiServe.getMonitorings(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.objPages = result.data;
                    this.rows = result.data.items
                    // this.componentKey++
                } else {
                    this.$toast.error(this.$t('error'))


                    var errorText = 'Не вийшло отримати моніторинги'; 
                    var statusRequest = result.status;
                    var api = 'getMonitorings';
                    var fileRequest = 'src/views/monitoring/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },

    },
    created() {
        this.getdata()
    }
}
</script>

<style>

</style>